// for Welcome page
.family-block {
    background-color: #a2a2a2;
    font-size: 14px;
    margin-bottom: 20px;
}
.event-block {
    font-size: 14px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 10px;
}
.comment-block {
    border-bottom: 1px solid grey;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 320px;
}
.table-item, .table-item-square {
    background-color: white;
    text-align: center;
    padding-top: 2%;
    cursor: pointer;
}
.table-item-round {
    background-color: white;
    text-align: center;
    border-radius: 1000px;
    padding-top: 8px;
    cursor: pointer;
}
.table-item-space {
    background-color: transparent;
    text-align: center;
    padding-top: 8px;
}
.table-new-table-square,
.table-new-table-round,
.table-new-space {
    background-color: white;
    border: 1px solid grey;
    margin: 5px;
    text-align: center;
    width: 73px;
    height: 73px;
    padding-top: 8px;
    display: inline-block;
    cursor: pointer;
}
.table-new-table-round {
    border-radius: 1000px;
}
.table-grid {
    background-color: grey;
    width: 1000px;
    display: inline-block;
}
.table-grid-guest-list {
    display: inline-block;
    vertical-align: top;
    height: 500px;
    overflow: auto;
}
.table-grid-list {
    position: fixed;
    background-color: lightgray;
    padding: 10px;
}
.table-family-block {
    border-bottom: 1px grey solid;
}
.table-member-block,
.table-member-block-need-table {
    padding-left: 5px;
    padding-right: 5px;
    background-color: white;
}
.table-member-block-need-table {
    background-color: rgba(255, 0, 0, 0.3);
    cursor: pointer;
}
.table-full {
    background-color: rgba(0, 0, 255, 0.2);
    color: rgba(255, 255, 255, 0.9);
}
.sub-header {
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.story-img {
    max-width: 300px;
}
.filled-circle {
    position: absolute;
    color: lightgrey !important;
}
.error {
    color: red;
    font-size: 12px;
}

.icon-white {
    background-color: white;
    width: 24px;
    height: 24px;
}

// for home page
.home-bg {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
}

.img-fit {
    object-fit: cover;
    height: 300px;
    width: 100%;
}

// for Getting Started Page
.getting-started-bg {
    width: 100%;
}

.loading {
    height: 100%;
    position: absolute;
    width: 100%;
    text-align: center;
    padding-top: 10%;
}

.css-levciy-MuiTablePagination-displayedRows {
    display: none;
}